<template>
  <BasePaginationContainer
    class="members-list-container"
    :total="total"
    :show-total="showTotal"
    :loading="isLoading"
    :items="members"
    :per-page="perPage"
    :load-first-page="true"
    :no-show-organizations="noShowMembers"
    @load-page="handleLoadOfNewItems"
  >
    <template v-if="canList" #default="{ items, loading }">
      <div
        v-if="isLoading"
        class="members w-100 align-items-center justify-content-center d-flex"
      >
        <b-spinner class="my-5" variant="primary" />
      </div>
      <div v-else>
        <MembersTable 
          :members="items" 
          :busy="loading" 
          :no-show-members="noShowMembers" 
          :is-searching="isSearching" 
          :is-filtering="isFiltering" 
          :filter="isFiltering"
          :filter-old="filter"
        />
      </div>
    </template>
  </BasePaginationContainer>
</template>

<script>
// import BasePaginationContainer from "@core/components/containers/base/BasePaginationContainer.vue";
// import MembersTable from "./MembersTable.vue";
import CardWidgetMixin from "@core/mixins/widgets/CardWidgetMixin";
import { checkPermissions } from "@/@core/utils/roles-utils";

export default {
  name: "MembersList",
  components: {
    BasePaginationContainer: () => import("@core/components/containers/base/BasePaginationContainer.vue" /* webpackChunkName: "BasePaginationContainer" */),
    MembersTable: () => import("./MembersTable.vue" /* webpackChunkName: "MembersTable" */)
  },
  mixins: [CardWidgetMixin],
  props: {
    isLoading: { type: Boolean, required: true },
    total: { type: Number, required: false },
    members: { type: Array || Object, required: true },
    perPage: {
      type: Number,
      default: 4,
    },
    organizationKey: {
      type: String,
      default: null,
    },
    noShowMembers: Boolean,
    isSearching: Boolean,
    isFiltering: Boolean,
    filter: Boolean,
  },
  // async created() {
  //   console.log('this.total', this.total)
  // },
  computed: {
    canList() {
      return (
        checkPermissions(
          "index",
          "member",
          this.loggedMemberRoles,
          this.collective
        ) || this.isStaff
      );
    },
    isStaff() {
      return this.collective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    showTotal() {
      const spacesNoShowTotal = [
        "7c23efd0-9d5e-11ed-804a-650ff09867b0"
      ];
      // console.log('this.collective', this.collective);
      return !spacesNoShowTotal.includes(this.collective.key);
    }
  },
  methods: {
    handleLoadOfNewItems({ page }) {
      this.$emit("handleLoadOfNewItems", { page });
    },
  },
};
</script>

<style lang="scss" scoped>
.members-list-container {
  overflow: hidden;
  flex: 1;
}
.toggle-filters-button {
  outline: none;
  box-shadow: none;
  min-width: 25px;
  margin-left: 10px;
}
.members {
  min-height: 451px;
}
</style>
